import { inject, Injectable } from '@angular/core';
import { DataleanBaseApiService, PartParam, ApiOptions, PaginationInfo, SearchInfo, Parts, SortInfo, UnknownObject, HttpOptions } from 'addiction-components';
import { environment } from '../../../../src/environments/environment';
import { PracticeInterface, SHARED_PARTS } from '@consenso-informato/models';
import { Practice } from '../models/practice';
import { PracticeSectionContainer } from '../models/practice-section-container';

@Injectable({
  providedIn: 'root'
})
export class PracticeService {
  private baseApi = inject(DataleanBaseApiService);

  getManyPracticesPaged(options?: Partial<{ parts: PartParam[]; options: ApiOptions }>) {
    return this.baseApi.getManyPaged<PracticeInterface>(environment.practiceUrl, options?.parts ?? [], options?.options);
  }

  getPractices(
    filters?: Partial<{
      pagination: PaginationInfo;
      searchInfo: SearchInfo;
      parts: Parts[];
      sortInfo?: SortInfo;
      additionalParams: UnknownObject;
      options: HttpOptions;
    }>
  ) {
    return this.baseApi.getEntities<PracticeInterface[]>(
      environment.practiceUrl,
      filters?.additionalParams,
      filters?.parts ?? [],
      filters?.searchInfo,
      filters?.pagination,
      filters?.sortInfo,
      filters?.options
    );
  }

  fetchPracticeSectionsContainer(params: Record<string, unknown> = {}, parts: Parts[] = []){
    return this.baseApi.getEntities<PracticeSectionContainer[]>(
      environment.practiceSectionContainerUrl,
      {
        latest: true,
        ...params
      },
      parts,
    )
  }

  fetchPracticeByUUID(uuid: string) {
    const parts: Parts[] = [SHARED_PARTS.PRACTICE_SECTION];

    return this.baseApi.getEntity<Practice>(environment.practiceUrl, uuid, parts, {});
  }

  updatePractice(practice: Practice) {
    return this.baseApi.updateOne(environment.practiceUrl, practice.uuid, practice, []);
  }

  patchPractice(body: Partial<Practice>, practiceUUID: string){
    return this.baseApi.patchOne(environment.practiceUrl, practiceUUID, body);
  }

  createPractice(practice: Practice) {
    return this.baseApi.createEntity<Practice>(environment.practiceUrl, practice, []);
  }

  deletePractice(uuid: string) {
    return this.baseApi.deleteOne(environment.practiceUrl, uuid);
  }
}
